<template>
  <PageSection :id="componentId">
    <div v-if="data?.data !== undefined && didReceiveData">
      <KInput
        v-if="enableSearch"
        v-model.trim="searchName"
        class="apis-search-input"
        :placeholder="t('apis.search_placeholder')"
        type="search"
      >
        <template #before>
          <FilterIcon />
        </template>
        <template #after>
          <button
            v-if="searchName"
            :aria-label="t('actions.clear_search')"
            :title="t('actions.clear_search')"
            type="button"
            @click="searchName = ''"
          >
            <CloseIcon decorative />
          </button>
        </template>
      </KInput>

      <PageContainer
        v-if="data.data.length"
        class="apis-list"
        display="grid"
        :grid-columns-breakpoints="gridColumnsBreakpoints"
      >
        <ApisCard
          v-for="api in data.data"
          :key="api.id"
          :api-description="api.description || ''"
          :api-id="api.id"
          :api-name="api.name || ''"
          class="api-card"
          :data-testid="`api-card-${api.slug || api.id}`"
          :latest-version-name="api.version || ''"
        >
          <template #actions>
            <slot
              :api="api"
              name="actions"
            />
          </template>

          <template #error>
            <slot
              :api="api"
              name="error"
            />
          </template>

          <template
            v-if="api.slug || api.id"
            #footer-left
          >
            <slot
              :api="api"
              name="footer-left"
            >
              <PortalKButton
                appearance="secondary"
                data-testid="api-docs-button"
                :to="{
                  name: 'apis-api_slug-docs',
                  params: {
                    api_slug: api.slug || api.id,
                  },
                }"
              >
                {{ buttonCtaText }}
              </PortalKButton>
            </slot>
          </template>
        </ApisCard>
      </PageContainer>

      <KPagination
        v-if="pagination && paginationPageSizes[0]! < Number(data?.meta?.page?.total || 0)"
        :current-page="currentPageNumber"
        data-testid="api-pagination"
        :page-sizes="paginationPageSizes"
        :total-count="data.meta.page.total"
        @page-change="setPage"
        @page-size-change="setPageSize"
      />

      <!-- no results sate -->
      <EmptyState
        v-if="!data.data.length && status !== 'pending'"
        class="no-results"
        :description="t('errors.apis.no_results')"
        :title="t('errors.apis.empty')"
      >
        <template #icon>
          <ConnectionsIcon decorative />
        </template>
        <template #action>
          <PortalKButton
            :disabled="enableSearch && !searchName"
            @click="clearData"
          >
            {{ t('actions.search_again') }}
          </PortalKButton>
        </template>
      </EmptyState>

      <!-- loading state -->
      <template v-if="!data.data.length && status === 'pending'">
        <PageContainer
          class="apis-list"
          display="grid"
          :grid-columns-breakpoints="gridColumnsBreakpoints"
        >
          <KSkeleton
            v-for="i of 4"
            :key="i"
            type="card"
          />
        </PageContainer>
      </template>
    </div>

    <EmptyState
      v-else-if="previewModeEnabled && error"
      :title="t('errors.preview_mode.restricted')"
    >
      <template #icon>
        <WarningIcon
          :color="`var(--kui-icon-color-warning, ${KUI_ICON_COLOR_WARNING})`"
          decorative
        />
      </template>
    </EmptyState>
    <EmptyState
      v-else-if="error"
      :description="error.statusMessage ? t('errors.apis.fetch') : undefined"
      :title="error.statusMessage || t('errors.apis.fetch')"
    >
      <template #icon>
        <WarningIcon
          :color="`var(--kui-icon-color-danger, ${KUI_ICON_COLOR_DANGER})`"
          decorative
        />
      </template>
    </EmptyState>
    <EmptyState
      v-else-if="status !== 'pending'"
      :description="t('errors.apis.no_results')"
      :title="t('apis.welcome_message')"
    >
      <template #icon>
        <ConnectionsIcon decorative />
      </template>
    </EmptyState>
    <div v-else-if="status === 'pending'" />
  </PageSection>
</template>

<script setup lang="ts">
import { CloseIcon, ConnectionsIcon, FilterIcon, WarningIcon } from '@kong/icons'
import { KUI_ICON_COLOR_WARNING, KUI_ICON_COLOR_DANGER } from '@kong/design-tokens'
import type { PageChangeData, PageSizeChangeData } from '@kong/kongponents'
import { watchDebounced } from '@vueuse/core'
import type { ApisListProps } from '#imports'

const {
  enableSearch = false,
  pagination = true,
  pageNumber,
  persistPageNumber = false,
  pageSize,
  pageSizes = [],
  gridColumnsBreakpoints = {
    mobile: 2,
    phablet: 3,
    tablet: 3,
    desktop: 4,
  },
  ctaText = '',
  styles = '',
} = defineProps<ApisListProps>()

// Inject any custom `props.styles` scoped by the `componentId` into the document head
const { componentId } = useCustomStyles(computed(() => styles), useAttrs().id as string)

const route = useRoute()
const router = useRouter()
const { t } = useI18n()
const apisListId = `apis-list-${ useId() }`
const { enabled: previewModeEnabled } = usePortalPreviewMode()
const buttonCtaText = computed((): string => String(ctaText || '').trim() || t('actions.view_api'))

// Compute the initial page number based on route query or props
const currentPageNumber = useState<number>(`page-number-${apisListId}`, () => {
  if (typeof pageNumber === 'number' && pageNumber > 0) {
    return pageNumber
  }
  if (persistPageNumber && !isNaN(Number(route.query.page)) && Number(route.query.page) > 0) {
    return Number(route.query.page)
  }

  // Default to the first page
  return 1
})
const defaultPageSizes: number[] = [24, 36, 48, 60]
const paginationPageSizes = useState<number[]>(`page-sizes-${apisListId}`, () => pageSizes.length ? pageSizes : defaultPageSizes)
const currentPageSize = useState<number>(`current-page-size-${apisListId}`, () => pageSize || paginationPageSizes.value[0] || defaultPageSizes[0]!)

const setPage = (event: PageChangeData) => {
  currentPageNumber.value = event.page
}
const setPageSize = (event: PageSizeChangeData) => {
  currentPageSize.value = event.pageSize
}

const searchName = useState<string>(`search-name-${apisListId}`, () => '')
const nameQuery = useState<string>(`name-query-${apisListId}`, () => '')

watchDebounced(searchName, (val) => {
  nameQuery.value = val
  currentPageNumber.value = 1
}, {
  debounce: 1000,
})

const clearData = ():void => {
  searchName.value = ''
  nameQuery.value = ''
  currentPageNumber.value = 1
}
// TODO: add auth_strategy_id filter when it's available
const query = computed(() => ({
  'page[number]': pagination ? currentPageNumber.value : 1,
  'page[size]': currentPageSize.value,
  'filter[name][contains]': nameQuery.value ? nameQuery.value : undefined,
}))

const { transform, getCachedData } = serveCachedData()
const { data, status, error } = await usePortalApi('/api/v3/apis', {
  query,
  watch: [() => pagination, currentPageSize, nameQuery, currentPageNumber],
  // Serve cached data if exists and not expired
  ...(!enableSearch ? [transform, getCachedData] : []),
})

const didReceiveData = useState<boolean>(`did-receive-data-${apisListId}`, () => false)

watch(data, (newData) => {
  if (!didReceiveData.value && newData?.data?.length) {
    didReceiveData.value = true
  }
}, { immediate: true })


// Did the component already retry resetting the page number on 400 error? (init as false)
const didRetryRestPageNumber = useState<boolean>(`did-retry-${apisListId}`, () => false)

// Watch the error object
watch(error, (err) => {
  if (import.meta.client) {

    // If the API returns a 400 error, it's likely due to an invalid page number, so reset to 1
    if (!didRetryRestPageNumber.value && err && (err.statusCode === 400 || String(err.data?.detail || '').toLowerCase().includes('page offset'))) {
      // Prevent an additional automatic retry
      didRetryRestPageNumber.value = true
      // Reset the page number to 1
      currentPageNumber.value = 1
    }
  }
}, { immediate: true })

// Update the route.query.page when the currentPageNumber changes
watch(currentPageNumber, (page) => {
  // If persistPageNumber is disabled, exit early
  if (!persistPageNumber) {
    return
  }

  // If the page number is less than 2, remove the query parameter
  if (!page || page < 2) {
    router.push({
      query: {
        // Keep other query params
        ...route.query,
        // Remove page query param
        ...{ page: undefined },
      },
    })
    return
  }

  // Add the page number query parameter
  router.push({
    query: {
      ...route.query,
      page,
    },
  })
}, { immediate: true })

onBeforeUnmount(async () => {
  clearData()
})
</script>

<style lang="scss" scoped>
.apis {
  &-list {
    margin: var(--kui-space-70, $kui-space-70) var(--kui-space-0, $kui-space-0) !important;
  }

  &-search-input {
    :deep(input[type="search"]) {
      padding-left: 40px!important;

      &::-webkit-search-cancel-button {
        display: none;
      }
    }
  }
}

.api-card-content {
  display: flex;
  flex-direction: column;
  gap: var(--kui-space-70, $kui-space-70);
  height: 100%;
  justify-content: space-between;

  h1, h2, h3, h4, h5, p:first-of-type {
    margin-top: var(--kui-space-0, $kui-space-0);
  }
}

.api-card-footer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.no-results {
  margin-top: var(--kui-space-70, $kui-space-70);
}
</style>
